import { Country, useGetCountriesQuery } from 'api';
import { useMeta } from '../../../hooks/useMeta';
import { ensureArray } from 'system';
import { useMemo } from 'react';

export const getProvincesForCountry = (country?: string, countries?: Country[]) =>
  ensureArray(
    country
      ? countries
          ?.find((c) => c.id === country)
          ?.provinces?.map((p) => ({
            label: p.name,
            value: p.code,
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label))
      : []
  );

export const useCountries = () => {
  const { data, ...meta } = useGetCountriesQuery();

  const { loading } = useMeta(meta);

  const countries = useMemo(
    () => [...ensureArray(data?.countries)].sort((a, b) => a.name.localeCompare(b.name)),
    [data?.countries]
  );

  const timezones = useMemo(() => {
    const tzMap: Record<string, string> = {};
    countries.forEach((country) => {
      if (country.timezone) {
        tzMap[country.timezone] = country.id;
      }
      country.provinces?.forEach((province) => {
        if (province.timezone) {
          tzMap[province.timezone] = country.id;
        }
      });
    });
    return tzMap;
  }, [countries]);

  const userCountry = useMemo(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezones[timeZone as keyof typeof timezones] ?? 'CA';
  }, [timezones]);

  const getProvinceLabel = (country?: string) =>
    country ? (countries.find((c) => c.id === country)?.provinceLabel ?? 'Province') : 'Province';

  const getPostalLabel = (country?: string) =>
    country
      ? (countries.find((c) => c.id === country)?.postalLabel ?? 'Postal Code')
      : 'Postal Code';

  return { countries, loading, userCountry, getProvinceLabel, getPostalLabel };
};
