import { Stack } from '@mui/material';
import { DropdownFieldController, GooglePlacesAutocomplete, TextFieldController } from 'components';
import { useFormContext } from 'react-hook-form';
import { AddressField, addressFieldSchema } from '../schema';
import { WizardStep } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { useEffect, useMemo } from 'react';
import { getProvincesForCountry, useCountries } from 'apps/portal/hooks/useCountries';

export function AddressStep() {
  const { setValue, watch } = useFormContext<AddressField>();
  const { countries, userCountry, getProvinceLabel, getPostalLabel } = useCountries();

  const streetName = watch('streetName');
  const country = watch('country');
  const provinces = useMemo(() => getProvincesForCountry(country, countries), [countries, country]);

  useEffect(() => {
    if (!country) {
      setValue('country', userCountry);
    }
  }, [userCountry]);

  return (
    <WizardStep
      schema={addressFieldSchema}
      stepperVariant="linear"
      spacing={0}
      title={<Header>What is the address?</Header>}
    >
      <Stack gap={3} sx={{ my: 2 }}>
        <GooglePlacesAutocomplete
          disableClearable={true}
          country={country}
          initialValue={streetName}
          onChange={(location) => {
            if (location?.streetName) setValue('streetName', location.streetName);
            if (location?.city) setValue('city', location.city);
            if (location?.province) setValue('province', location.province);
            if (location?.postalCode) setValue('postalCode', location.postalCode);
            if (location?.country) setValue('country', location.country);
          }}
          renderInput={(params) => {
            const paramsWithoutAdornment = {
              ...params,
              InputProps: {
                ...params.InputProps,
                endAdornment: null,
              },
            };
            return (
              <TextFieldController
                {...paramsWithoutAdornment}
                name="streetName"
                label="Street"
                variant="outlined"
              />
            );
          }}
        />
        <TextFieldController name="city" label="City" variant="outlined" />
        <DropdownFieldController
          variant="outlined"
          name="province"
          label={getProvinceLabel(country)}
          data={provinces}
        />
        <DropdownFieldController
          name="country"
          label="Country"
          variant="outlined"
          data={countries?.map((c) => ({
            label: c.name,
            value: c.id,
          }))}
        />
        <TextFieldController name="postalCode" label={getPostalLabel(country)} variant="outlined" />
      </Stack>
    </WizardStep>
  );
}
