import { maybe, nullableValidPhone } from 'system';
import { z } from 'zod';
import { yesNoShape } from '../WorkHistoryWizardForm/schema';
import { DateTime } from 'luxon';

export const addressFieldSchema = z.object({
  streetName: z.string().min(1, 'Street name is required'),
  city: maybe(z.string()),
  province: maybe(z.string()),
  postalCode: maybe(z.string()),
  country: maybe(z.string()),
});

export type AddressField = z.infer<typeof addressFieldSchema>;

export const durationSchema = z.object({
  startDate: z
    .string()
    .min(1, 'Start Date is required')
    .refine((value) => DateTime.fromISO(value).isValid, {
      message: 'Invalid date format',
      path: [],
    }),
  endDate: maybe(
    z.string().refine((value) => DateTime.fromISO(value).isValid, {
      message: 'Invalid date format',
      path: [],
    })
  ),
});
export type DurationFields = z.infer<typeof durationSchema>;

export const landlordSchema = z.object({
  name: z.string().min(1, 'Name of landlord is required'),
  phone: maybe(
    z.string().refine(nullableValidPhone, {
      message: 'Phone must be empty or a valid phone number',
    })
  ),
  email: maybe(z.string().email('Email must be empty or a valid email address')),
});
export type LandlordField = z.infer<typeof landlordSchema>;

export const rentalTypeFieldSchema = z.object({
  rentalType: z.string().min(1, 'This field is required'),
});
export type RentalTypeField = z.infer<typeof rentalTypeFieldSchema>;

export const confirmAdditionalAddressSchema = z.object({
  hasOtherAddress: yesNoShape,
});
export type ConfirmAdditionalAddressField = z.infer<typeof confirmAdditionalAddressSchema>;

export const addressHistoryFieldsSchema = durationSchema
  .merge(addressFieldSchema)
  .merge(
    z.object({
      name: maybe(z.string()),
      phone: maybe(
        z.string().refine(nullableValidPhone, {
          message: 'Phone must be empty or a valid phone number',
        })
      ),
      email: maybe(z.string().email('Email must be empty or a valid email address')),
    })
  )
  .merge(rentalTypeFieldSchema)
  .merge(
    z.object({
      hasOtherAddress: maybe(yesNoShape),
    })
  );

export type AddressHistoryFields = z.infer<typeof addressHistoryFieldsSchema>;
