import { Stack } from '@mui/material';
import { DropdownFieldController, TextFieldController } from 'components';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { LocationField, locationFieldSchema } from '../schema';
import { getProvincesForCountry, useCountries } from 'apps/portal/hooks/useCountries';
import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

export function LocationStep(props: WizardStepProps<typeof locationFieldSchema>) {
  const { countries, userCountry, getProvinceLabel } = useCountries();
  const { watch, setValue } = useFormContext<LocationField>();
  const country = watch('country');
  const provinces = useMemo(() => getProvincesForCountry(country, countries), [countries, country]);

  useEffect(() => {
    if (!country) {
      setValue('country', userCountry);
    }
  }, [userCountry]);

  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>What is their location?</Header>}
      schema={locationFieldSchema}
      {...props}
    >
      <Stack gap={2} sx={{ my: 2 }}>
        <TextFieldController
          variant="outlined"
          label="City"
          name="city"
          placeholder="e.g. Vancouver"
        />
        <DropdownFieldController
          variant="outlined"
          name="province"
          label={getProvinceLabel(country)}
          data={provinces}
        />
        <DropdownFieldController
          variant="outlined"
          name="country"
          label="Country"
          data={countries?.map((c) => ({
            label: c.name,
            value: c.id,
          }))}
        />
      </Stack>
    </WizardStep>
  );
}
