import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Place, PlacePrediction, useGoogleAutocompletePredictions } from 'hooks';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export type AutocompleteQuery = {
  input?: string;
  types?: string[];
  componentRestrictions?: {
    country: string[];
  };
};

type GooglePlacesAutocompleteProps = {
  initialValue?: string;
  autocompleteRequest?: Omit<google.maps.places.AutocompletionRequest, 'input'>;
  disableClearable?: boolean;
  onChange?: (location: Place) => void;
  renderInput: (params: AutocompleteRenderInputParams) => JSX.Element;
  country?: string;
};

export default function GooglePlacesAutocomplete({
  initialValue,
  disableClearable,
  autocompleteRequest,
  onChange,
  country,
  renderInput,
}: GooglePlacesAutocompleteProps) {
  const classes = useStyles();
  const placesDivRef = useRef<HTMLDivElement>(null);

  const [searchValue, setSearchValue] = useState(initialValue ?? '');
  const [selectedValue, setSelectedValue] = useState<PlacePrediction>(initialValue ?? '');

  useEffect(() => {
    setSearchValue(initialValue ?? '');
  }, [initialValue]);

  const autocompletionRequest: google.maps.places.AutocompletionRequest = useMemo(
    () => ({
      input: searchValue,
      ...(autocompleteRequest ?? {}),
      ...(country && { componentRestrictions: { country: [country] } }),
    }),
    [searchValue, autocompleteRequest, country]
  );

  const { options, place, loadingOptions, loadingPlace } = useGoogleAutocompletePredictions(
    autocompletionRequest,
    selectedValue,
    placesDivRef
  );

  const initialized = useRef(false);
  useLayoutEffect(() => {
    if (!initialized.current || place === null) {
      initialized.current = true;
      return;
    }

    onChange?.(place);
    setSearchValue(place?.formattedAddress ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  return (
    <>
      <div ref={placesDivRef}></div>

      <Autocomplete
        disableClearable={disableClearable}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : (place?.formattedAddress ?? '')
        }
        freeSolo
        options={options}
        disabled={loadingPlace}
        loading={loadingOptions}
        value={selectedValue}
        inputValue={searchValue}
        filterOptions={(x) => x}
        onChange={(event, newValue) => event && setSelectedValue(newValue)}
        onInputChange={(event, newSearchValue) => event && setSearchValue(newSearchValue)}
        renderInput={renderInput}
        renderOption={(props, option) => (
          <li key={option?.toString()} {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {typeof option === 'string' ? (
                  <Typography variant="body1">{option}</Typography>
                ) : (
                  <>
                    <Typography variant="body1">{option?.mainText}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {option?.subText}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </li>
        )}
      />
    </>
  );
}
